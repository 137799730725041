import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AddNewDriverRequest, GetDriversResponse } from '../models/driver.model';

@Injectable({
  providedIn: 'root',
})
export class DriversService {

  constructor(private http: HttpClient) { }

  getDrivers(): Observable<HttpResponse<GetDriversResponse>> {
    return this.http.get<GetDriversResponse>(environment.baseUrl + 'User/GetAllDriversForCurrentCourierAdmin', { observe: 'response' });
  }

  addNewDriver(payload: AddNewDriverRequest): Observable<any> {
    return this.http.post(environment.baseUrl + 'User/AddDriver', payload, { observe: 'response'});
  }

}
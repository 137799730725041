import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Actions, Store } from '@ngxs/store';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @ViewChild('sideNav') sideNav: MatSidenav;
  
  //User Information
  public token: string;
  public username: string;

  // View States
  public viewWidth: number = 0;
  public isDesktop: boolean = false;
  // Badge states
  public hideBadge: boolean = true;
  public badgeNumber: number = 10;

  //Language
  public lang: string = "en";

  constructor(
    public translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public router: Router,
    private store: Store,
    private actions: Actions
  ) { 

    this.addIcons();
  }

  ngOnInit(): void {
    this.getToken();
    this.viewWidth = window.innerWidth;
    if (this.viewWidth > 1280) {
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }
  }

  getToken(): void {
    this.token = this.store.snapshot().auth.token;
    this.username = this.store.snapshot().auth.username;
  }


  addIcons(): void {
    this.matIconRegistry.addSvgIcon('burger-menu', this.sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/burger-menu.svg'));
    this.matIconRegistry.addSvgIcon('logout', this.sanitizer.bypassSecurityTrustResourceUrl('../../assets/icons/logout.svg'));
  }

  changeLang(): void {
    if (this.translate.currentLang == "ar") {
      this.translate.use('en');
      this.lang = "en";
    } else {
      this.translate.use("ar");
      this.lang = "ar";
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.viewWidth = event.target.innerWidth;
    this.isDesktop = false;
    if (this.viewWidth > 1280) {
      this.isDesktop = true;
    }
  }

  resetPosition() {
    const elm = document.getElementById("page-container");
    if(elm){
      elm.scrollTop = 0;
    }
  }

  logout() {
    
  }

}

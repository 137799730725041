import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationComponent } from './core/navigation/navigation.component';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'app/orders', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule)
  },
  {
    path: 'app',
    component: NavigationComponent,
    children: [
      {
        path:'',
        redirectTo: 'orders',
        pathMatch: 'full'
      },
      {
        path:'orders',
        loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
        canLoad: [AuthGuard]
      },
      {
        path:'drivers',
        loadChildren: () => import('./drivers/drivers.module').then(m => m.DriversModule),
        canLoad: [AuthGuard]
      },
      {
        path:'pickup-requests',
        loadChildren: () => import('./pickup-requests/pickup-requests.module').then(m => m.PickupsModule),
        canLoad: [AuthGuard]
      },
    ]
  },
  // To be replaced with 404 not found page
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

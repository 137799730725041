import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { GetCourierHubsResponse, LoginRequest, LoginResponse } from '../models/login.model';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService {

  constructor(private http: HttpClient) { }

  authenticateUser(payload: LoginRequest): Observable<HttpResponse<LoginResponse>> {

    return this.http.post<LoginResponse>(environment.baseUrl + 'Authentication/Validate', payload, { observe: 'response'});
  }

  getCourierHubs(): Observable<HttpResponse<GetCourierHubsResponse>> {
    return this.http.get<GetCourierHubsResponse>(environment.baseUrl + 'User/GetHubsForCurrentCourierAdmin', { observe: 'response'});
  }

}
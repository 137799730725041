import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { LoginState } from "../../store/login/login.state";
import { Store } from "@ngxs/store";
import { LoginService } from "src/app/shared/services/login.service";
import { CookieService } from "ngx-cookie-service";
import { loginAction } from "src/app/store/login/login.actions";
import { Router } from "@angular/router";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private store: Store, private cookieService: CookieService, private router: Router
  ) { }

  getAuthToken(): string {
    let cookieValue: string = this.cookieService.get("X-Goog-Authenticated-User-Token");
    return cookieValue.length > 0 ? cookieValue : null;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    if(request.url.includes('/Authentication/Validate')) {
      return next.handle(request);
    }

    request = request.clone({
      headers: request.headers.set(
        "Authorization",
        `Bearer ${this.getToken()}`
      )
    });

    request = request.clone({
      headers : request.headers.set(
        'Accept',
        '*/*'
      )
    });

    return next.handle(request).pipe(catchError(err => {
      if (err.status === 401) {
        return this.handle401Error(request, next);
      } else {
        return throwError("failed to load resource");
      }
      
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    this.router.navigate(['/app', 'login']);
    return next.handle(request);
  }

  getToken(): string {
    return this.store.snapshot().auth.token;
  }

}
//Auth request body
export interface LoginRequest {
    username: string;
    password: string;
}

//Auth Response body
export interface LoginResponse {
    refresh?: string;
    token: string;
    id? : string;
    username : string;
    role: string;
}

export interface Hub {
    name: string;
    address: string;
}

export interface GetCourierHubsResponse {
    hubs: Hub[];
}


// Enums
export enum UserRolesEnum {
    SUPER_ADMIN = "superadmin",
    COURIER_ADMIN = "courier_admin"
}
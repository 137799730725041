import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { AssignOrdersToDriver, GetCitiesAction, GetCitiesAreasAction, GetCompanyWarehousesAction, GetOrdersAction, ScanOrderAction } from './orders.actions';
import { OrdersService } from 'src/app/shared/services/orders.service';
import { HttpResponse } from '@angular/common/http';
import { Area, City, CompanyWarehouse, GetOrdersResponse, Order } from 'src/app/shared/models/order.model';

export interface OrdersStateModel {
    orders: Order[];
    cities: City[];
    areas: Area[];
    companyWarehouses: CompanyWarehouse[];
    scannedOrder: Order,
    pagesCount: number
}


@State<OrdersStateModel>({
    name: 'orders',
    defaults: {
      orders: [],
      cities: [],
      areas: [],
      companyWarehouses: [],
      scannedOrder: null,
      pagesCount: 0
    }
  })
@Injectable()
export class OrdersState {

  constructor(private ordersService: OrdersService) { 
  }
  
  @Action(GetOrdersAction)
  public GetOrders({ patchState }: StateContext<OrdersStateModel>, { payload }: GetOrdersAction) {
    return this.ordersService.getOrders(payload).pipe(
      tap((res: HttpResponse<GetOrdersResponse>) => {
        if (res.status === 200) {
          //success
          patchState({
            orders: res.body.orders,
            pagesCount: res.body.pagesCount
          });
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @Action(AssignOrdersToDriver)
  public assignOrdersToDriver({ patchState }: StateContext<OrdersStateModel>, { payload }: AssignOrdersToDriver) {
    return this.ordersService.assignOrdersToDriver(payload).pipe(
      tap((res: HttpResponse<GetOrdersResponse>) => {
        if (res.status === 200) {
          //success
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @Action(GetCitiesAction)
  public getCities({ patchState }: StateContext<OrdersStateModel>, {  }: GetCitiesAction) {
    return this.ordersService.getCities().pipe(
      tap((res: HttpResponse<any>) => {
        if (res.status === 200) {
          //success
          patchState({
            cities: res.body.cities
          });
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @Action(GetCitiesAreasAction)
  public getCitiesAreas({ patchState }: StateContext<OrdersStateModel>, { payload }: GetCitiesAreasAction) {
    return this.ordersService.getCitiesAreas(payload).pipe(
      tap((res: HttpResponse<any>) => {
        if (res.status === 200) {
          //success
          patchState({
            areas: res.body.areas
          });
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @Action(GetCompanyWarehousesAction)
  public getCompanyWarehouses({ patchState }: StateContext<OrdersStateModel>, {  }: GetCompanyWarehousesAction) {
    return this.ordersService.getComapnyWarehouses().pipe(
      tap((res: HttpResponse<any>) => {
        if (res.status === 200) {
          //success
          patchState({
            companyWarehouses: res.body.warehouses
          });
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @Action(ScanOrderAction)
  public scanOrder({ patchState }: StateContext<OrdersStateModel>, { payload }: ScanOrderAction) {
    return this.ordersService.scanOrder(payload).pipe(
      tap((res: HttpResponse<any>) => {
        if (res.status === 200) {
          //success
          patchState({
            scannedOrder: res.body.order
          });
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

}
import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { AddNewDriverRequest, Driver, GetDriversResponse } from 'src/app/shared/models/driver.model';
import { AddNewDriverAction, GetDriversAction } from './drivers.actions';
import { DriversService } from 'src/app/shared/services/drivers.service';

export interface DriversStateModel {
    drivers: Driver[];
}


@State<DriversStateModel>({
    name: 'drivers',
    defaults: {
      drivers: []
    }
  })
@Injectable()
export class DriversState {

  constructor(private driversService: DriversService) { 
  }

  @Action(GetDriversAction)
  public GetDrivers({patchState}: StateContext<DriversStateModel>, {}:GetDriversAction) {
    return this.driversService.getDrivers().pipe(
      tap((res:HttpResponse<GetDriversResponse>)=>{
        if(res.status === 200) {
          patchState({
            drivers: res.body.drivers
          });
        }
      }),
      catchError((err)=>{
        throw err;
      })
    );
  }

  @Action(AddNewDriverAction)
  public AddNewDriver({patchState}: StateContext<DriversStateModel>, { payload }:AddNewDriverAction) {
    return this.driversService.addNewDriver(payload).pipe(
      tap((res:HttpResponse<GetDriversResponse>)=>{
        if(res.status === 200) {
        }
      }),
      catchError((err)=>{
        throw err;
      })
    );
  }
}
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse,HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AssignPickupsToDriverRequest, GetPickupsRequest, GetPickupsResponse ,UpdatePickupRequest} from '../models/pickup.model';

@Injectable({
  providedIn: 'root',
})
export class PickupsService {

  constructor(private http: HttpClient) {

  }
        getPickups(payload: GetPickupsRequest): Observable<HttpResponse<GetPickupsResponse>> {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
          });

          return this.http.post<GetPickupsResponse>(
            environment.baseUrl + 'api/Pickup/ListPickups',
            payload,
            { headers, observe: 'response' }
          );
        }

        assignPickupsToDriver(payload: AssignPickupsToDriverRequest): Observable<HttpResponse<GetPickupsResponse>> {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
          });

          return this.http.post<GetPickupsResponse>(
            environment.baseUrl + 'api/Pickup/AssignPickupsToDriver',
            payload,
            { headers, observe: 'response' }
          );
        }
        updatePickupStatus(payload: UpdatePickupRequest): Observable<HttpResponse<GetPickupsResponse>> {
          const headers = new HttpHeaders({
            'Content-Type': 'application/json',
          });

          return this.http.post<GetPickupsResponse>(
            environment.baseUrl + 'api/Pickup/UpdatePickupStatus',
            payload,
            { headers, observe: 'response' }
          );
        }



}

import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { LoginState } from 'src/app/store/login/login.state';
import { UserRolesEnum } from 'src/app/shared/models/login.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {
  constructor(private store: Store, private router: Router) { }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
      const token = this.store.snapshot().auth.token;
      const username = this.store.snapshot().auth.username;
      const role = this.store.snapshot().auth.role;
      if (token && username && (role == UserRolesEnum.COURIER_ADMIN || role == UserRolesEnum.SUPER_ADMIN)) {
        return true;
      } else {
        this.router.navigate(['/login']);
        return false;
      }
  }
}
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'delivery-app-admin-ui';

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');

    // changes the document direction depending on the languages
    translate.onLangChange.subscribe((langChange) => {
      if (langChange.lang === 'ar') {
        document.dir = 'rtl';
      } else {
        document.dir = 'ltr';
      }
    });

    const browserLang = translate.getBrowserLang();
    translate.use(browserLang?.match(/en|ar/) ? browserLang : 'en');
  }


}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AssignOrdersToDriverRequest, GetOrdersRequest, GetOrdersResponse, ScanOrdersRequest } from '../models/order.model';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  getOrders(payload: GetOrdersRequest): Observable<HttpResponse<GetOrdersResponse>> {
    return this.http.post<GetOrdersResponse>(environment.baseUrl + 'Order/GetOrdersBasedOnCurrentUser', payload, { observe: 'response' });
  }

  assignOrdersToDriver(payload: AssignOrdersToDriverRequest): Observable<any> {
    return this.http.post(environment.baseUrl + 'Order/AssignOrdersToDriver', payload, { observe: 'response'});
  }

  getCities(): Observable<any> {
    return this.http.get(environment.baseUrl + 'city/GetAll', { observe: 'response'});
  }

  getCitiesAreas(payload: string[]): Observable<any> {
    return this.http.post(environment.baseUrl + 'area/GetAreasBasedOnCities', payload, { observe: 'response'});
  }

  getComapnyWarehouses(): Observable<any> {
    return this.http.get(environment.baseUrl + 'warehouse/GetAll', { observe: 'response'});
  }

  scanOrder(payload: ScanOrdersRequest): Observable<any> {
    return this.http.post(environment.baseUrl + 'order/ScanOrderBasedOnUser', payload, { observe: 'response'});
  }

}
import { AddNewDriverRequest } from "src/app/shared/models/driver.model";

export class GetDriversAction {
    public static readonly type = '[Driver] Get Drivers';
    constructor() {}
}

export class AddNewDriverAction {
    public static readonly type = '[Driver] Add New Driver';
    constructor(public payload: AddNewDriverRequest) {}
}
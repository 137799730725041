import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';

//State Management
import { GetCourierHubsAction, loginAction } from './login.actions';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';

//Models
import { GetCourierHubsResponse, Hub, LoginResponse, UserRolesEnum } from '../../shared/models/login.model';

//Services
import { LoginService } from 'src/app/shared/services/login.service';


export interface LoginStateModel {
  token: string;   
  refresh?: string;
  id? : string;
  username : string;
  role: string;
  hubs?: Hub[];
}
@State<LoginStateModel>({
    name: 'auth',
    defaults: {
      token: null,   
      refresh: null,
      id: null,
      username : null,
      role: null,
      hubs: []
    }
  })
@Injectable()
export class LoginState {

  constructor(private loginService: LoginService, private router: Router) { }

  @Action(loginAction)
  public login({ patchState }: StateContext<LoginStateModel>, { payload }: loginAction) {
    return this.loginService.authenticateUser(payload).pipe(
      tap((res: HttpResponse<LoginResponse>) => {
        if (res.status === 200) {
          //success
          patchState({
            token: res.body.token,  
            refresh: res.body.refresh,
            id: res.body.id,
            username : res.body.username,
            role: res.body.role
          });
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @Action(GetCourierHubsAction)
  public getCourierHubs({ patchState }: StateContext<LoginStateModel>, { }: GetCourierHubsAction) {
    return this.loginService.getCourierHubs().pipe(
      tap((res: HttpResponse<GetCourierHubsResponse>) => {
        if (res.status === 200) {
          //success
          patchState({
            hubs: res.body.hubs
          });
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

}

import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { GetPickupsAction ,AssignPickupsToDriver,UpdatePickupStatusAction } from './pickups.actions';
import { PickupsService } from 'src/app/shared/services/pickups.service';
import { HttpResponse } from '@angular/common/http';
import {  GetPickupsResponse, Pickup } from 'src/app/shared/models/pickup.model';

export interface PickupsStateModel {
    pickups: Pickup[];
    pagesCount: number
}


@State<PickupsStateModel>({
    name: 'pickups',
    defaults: {
      pickups: [],
      pagesCount: 0
    }
  })
@Injectable()
export class PickupsState {

  constructor(private pickupsService: PickupsService) {

  }

  @Action(GetPickupsAction)
  public GetPickups({ patchState }: StateContext<PickupsStateModel>, { payload }: GetPickupsAction) {
    return this.pickupsService
    .getPickups(payload).pipe(tap((res: HttpResponse<GetPickupsResponse>) => {
        if (res.status === 200) {

          //success
          patchState({
            pickups: res.body.pickups,
            pagesCount: res.body.pagesCount
          });
        }
        else{
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @Action(AssignPickupsToDriver)
  public assignOrdersToDriver({ patchState }: StateContext<PickupsStateModel>, { payload }: AssignPickupsToDriver) {
    return this.pickupsService.assignPickupsToDriver(payload).pipe(
      tap((res: HttpResponse<GetPickupsResponse>) => {
        if (res.status === 200) {
          //success
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

  @Action(UpdatePickupStatusAction)
  public UpdatePickupStatus({ patchState }: StateContext<PickupsStateModel>, { payload }: UpdatePickupStatusAction) {
    return this.pickupsService.updatePickupStatus(payload).pipe(
      tap((res: HttpResponse<GetPickupsResponse>) => {
        if (res.status === 200) {
          //success
        }
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

}

import { LoginRequest } from '../../shared/models/login.model';

export class loginAction {
  public static readonly type = '[Auth] Authenticate Admin';
  constructor(public payload: LoginRequest) {}
}

export class GetCourierHubsAction {
  public static readonly type = '[Courier Hubs] List Courier Hubs';
  constructor() {}
}


import { AssignOrdersToDriverRequest, GetOrdersRequest, ScanOrdersRequest } from "src/app/shared/models/order.model";

export class GetOrdersAction {
    public static readonly type = '[Orders] Get Orders';
    constructor(public payload: GetOrdersRequest) {}
}

export class AssignOrdersToDriver {
    public static readonly type = '[Orders] Assign Orders To Driver';
    constructor(public payload: AssignOrdersToDriverRequest) {}
}

export class GetCitiesAction {
    public static readonly type = '[Orders] Get Cities';
    constructor() {}
}

export class GetCitiesAreasAction {
    public static readonly type = '[Orders] Get Cities Areas';
    constructor(public payload: string[]) {}
}

export class GetCompanyWarehousesAction {
    public static readonly type = '[Orders] Get Company Warehouses';
    constructor() {}
}

export class ScanOrderAction {
    public static readonly type = '[Orders] Scan Orders For Receiving';
    constructor(public payload: ScanOrdersRequest) {}
}
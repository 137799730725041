import {  GetPickupsRequest, Pickup, PickupStatusesEnum , AssignPickupsToDriverRequest,UpdatePickupRequest } from 'src/app/shared/models/pickup.model';

export class GetPickupsAction{
  public static readonly type = '[Pickups] Get Pickups';
  constructor(public payload: GetPickupsRequest) {
  } //get pickups request for filters
}

export class AssignPickupsToDriver {
  public static readonly type = '[Pickups] Assign Pickups To Driver';
  constructor(public payload: AssignPickupsToDriverRequest) {}
}


export class UpdatePickupStatusAction {
  public static readonly type = '[Pickups] Update Pickup Status';
  constructor(public payload: UpdatePickupRequest) {}
}
